#breadcrumb {
	background:$breadcrumb-bg-color;
	padding:$vert-space/2 $horz-space/2;
	
	@media(max-width:$narrow) {
		padding:$vert-space/4 $vert-space/2;
	}
	.breadcrumb {
		color:$breadcrumb-active-color;
		display:inline-block;
		font:$breadcrumb-font;
		margin:$vert-space/4 0;
		
		span {
			font-size:0.875rem;
		
			&:after {
				color:$breadcrumb-div-color;
				content:" / ";
				margin-left:$horz-space/2;
				margin-right:$horz-space/2;
			}
		
			a {
				background:none;
				border:none;
				color:$breadcrumb-action-color;
				//background:$div-breadcrumbs no-repeat 100% 50%;
				
				&:hover {
					color:$breadcrumb-hover-color;
				}
			}
			
			&.first a {
				padding-left: 0;
			}
			
			&.last {
				//padding-left: 15px;
				
				&:after {
					content:normal;
				}
			}
		}
	}
}
