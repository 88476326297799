// /* Add all of your colours to var-colors and apply them to different remora elements in var-config. Unless a style needs to be backported to remora, you do not need to create anything in var-config. Instead, override it in custom client scss files. */


// /* TEXT */ //
$primary   						: #41454D; /* outer_space */ 
$secondary 						: #6B7280; /* aurometalsaurus */ 
 

// /* MAIN PALETTE */ //
$black								: #000;
$grey									: #666;
$white								: #FFF;

$outer_space					: #41454D; /* main grey */

// /* SEC PALETTE */ //
$pale_grey						: #f5f5f5; /* pale_grey */
$glitter							: #E9ECF2;

$midnight_green				: #114257; /* link */
$bright_cerulean			: #27A9E0; /* link hover */
$sangria							: #8F0005; /* link hover v2 */

$alice_blue						: #EBF9FF; /* sidebar boxes bg v2 */
$folly								: #FF0048; /* slider dots / menu*/

$aurometalsaurus			: #6B7280; /* menu bg */ 
$platinum							: #E4E8EB; /* menu bg hover */
$mint_cream						: #F5F8FA; /* menu bg sub / site bg */
$japanese_indigo			: #2C3F4D; /* menu text */
 


// /* LINKS */ //
$action-default				: $bright_cerulean;
$action-hover 				: $midnight_green;
$action-active 				: $midnight_green; 
$action-active-hover 	: $midnight_green; 


// /* SHADOWS */ //
$box-shadow : 0 1px 8px 0 rgba(0,0,0,0.12);


// /* BORDERS */ //
$primary-border-color          : rgba(44, 63, 78,0.1);
$primary-border-radius         : 0;
$primary-border                : 1px solid $primary-border-color;

$secondary-border-color        : rgba(44, 63, 78,0.1);
$secondary-border-color-radius : 0;
$secondary-border              : 1px solid $secondary-border-color;

$tertiary-border-color         : #D6D6D6;
$tertiary-border-radius        : 0;
$tertiary-border               : 1px solid $tertiary-border-color;

$base-border                   : 1px solid rgba(44, 63, 78,0.1);
$base-border-radius            : 0;


// /* TABLES */ //
$table-bg-color           : white;
$base-accent-color        : #477DCA !default;
$table-border-color       : $primary-border-color;
$table-cell-border        : 1px solid $pale_grey;
$table-border             : 1px solid $table-border-color;
$table-header-bg-color    : $pale_grey;
$table-header-border      : 1px solid darken($primary-border-color, 10%);
$table-hover-bg-color     : lighten($grey, 42%);
$table-stripe-bg          : darken($table-bg-color, 4);
$table-stripe-bg-hover    : darken($table-stripe-bg, 5);
$table-padding            : .75em 1em;
$table-caption-bg-color   : $grey;
$table-caption-text-color : white;
$table-caption-border     : 1px solid white;
$table-caption-font       : 1.125em/1.33333333333333 $title-font-family;

// /* FORMS  */ //
$fieldset-border-color : $platinum;
$form-box-shadow       : none; /*$box-shadow;*/
$form-box-shadow-focus : none; /*$box-shadow;*/ 
$form-border-color     : $secondary-border-color;
$form-border           : 1px solid $platinum; /*1px solid $secondary-border;*/
$form-border-radius    : 0;

$button-bg             : $folly;
$button-hover-bg       : $action-default;
$button-text           : white;
$button-border         : none;
$button-border-radius  : 0;
$button-hover-text     : white;

$button-bg-reset       : $sangria;
$button-hover-bg-reset : $action-hover;


// /* MESSAGES */ //
$alert-color           : lighten(#f7db55, 33%); // /* CLASS: warning */
$error-color           : lighten(#fa4e7b, 33%); // /* CLASS: error */
$notice-color          : lighten(#5d86c5, 38%); // /* CLASS: notice */
$success-color         : lighten(#79c68a, 35%); // /* CLASS: status */
$message-border-radius : 3px;
$message-border-style  : 1px dotted;


// /* STYLES FROMS NEATO */ //
$base-background-color : white; /*$mint_cream*/
$base-font-color       : $primary;
$primary-border-radius : 0;
$flex-box-background   : white;