.credit,
.vid-description {
	background: rgba(255, 255, 255, 0.75) none repeat scroll 0 0;
	border: none;
	color: $primary;
	cursor: pointer;
	display: block;
	float: right;
	font: 0.75em/1.125 $title-font-family;
	margin-bottom: -27px;
	margin: 8px 0;
	padding: 4px 15px 4px 15px;
	position: absolute;
	right: 0;
	top: 0;
	text-align: right;
	box-sizing: border-box;
	
	&:empty {
		display: none;
	}

	&.collapsed {
		background: rgba($bright_cerulean,0.75) $ico-info no-repeat 50% 50% / auto 60%;
		height: 30px;
		margin-right: 0;
		text-align: inherit;
		text-indent: -9999px;
		width: 16px;
		transition: background-color 0.5s linear;
		border-radius: 50%;
		box-sizing: border-box;

		&:hover {
			background-color: rgba($bright_cerulean, 1);
		}
	}
}