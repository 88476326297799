@media (min-width:$wide) {
	.no-sidebars .node-content .card-summary,
	.no-sidebars .node-content .card-intro,
	.no-sidebars .node-content .card-body,
	.no-sidebars .node-content .card-authors {
		padding:0 16% 0 0;
	}
}
.node-leaf {
  main {
    background: $main-page-bg;
  }
  #content {
    padding: $vert-space;
    background-color: white;
		overflow:hidden;
    
    .card-title-field h1 {
      font-size: 2rem;
      font-weight: normal;
    }
    .card-intro {
      margin-top: $vert-space;
    }
    .card-image {
      float: left;
      margin: 0 $vert-space $horz-space 0;
      
      @media(min-width:640px){
        max-width:55%;
      }
    }
  }
}

main section#content {
	.content-main,
	.content-additional {
		/*background: $white;*/
		padding: 0 20px;
		
		.section-row {
			overflow: inherit;
		}
	}
}

.node-content {
	h1 .card-title-field {
		font-size:2rem;
		line-height:1.1875;
		color:$primary;
		font-family:$title-font-family;
		margin:0 0 0;
		text-transform:none;
	}
	.card-image {
		margin-bottom:$vert-space;
	}
	.card-display-date {
		font-size:0.875rem;
		margin-right:3px;
		padding:0;
		border-bottom: none;
	}
	.card-location {
    display: inline-block;
    font-size: 0.8125em;
	}
	.card-date,
	.card-display-date,
	.card-location {
		margin-bottom:$vert-space*0.75;
	}
	.card-job-title {
		color:$secondary;
		font-size:1.125em;
	}
  .card-summary {
    color:$primary;
    font-size:1.25em;
    font-weight:bold;
    line-height:1.3;
  }
	&.node-publication {
		.card-authors,
		.card-journal,
		.card-year,
		.card-volume,
		.card-pages,
		.card-doi,
		.card-pmid {
			display:inline-block;
    	font: 0.8125em/1.30769230769231 $base-font-family;
	
			.label {
				display:inline;
			}
		}
		.card-authors,
		.card-abbr-authors {
			font-style:italic;
			margin-bottom:$vert-space/4;
		}
		.card-journal:after {
			content:", ";
		}
		.card-display-date {
			display:inline-block;
		}
		.card-display-date:after {
			content:"; ";
		}
		.card-year:after {
			content:"; ";
		}
		.card-volume:after {
			content:": ";
		}
		.card-journal,
		.card-year {
			font-weight:bold;
		}
		.card-body {
			margin-top:$vert-space;
		}
	}
	
}
.node-type-publication {
	.content-main .card-citation-date {
		&::before {
			content: "Year: ";
			font-weight: 600;
		}
	}
}
.node-type-event,
.node-type-news {
	.content-main {
		.card-location,
		.card-display-date,
		.card-date {
			display: inline-block;
			margin: 0 1em 1em 0;
			background: $alice_blue;
			line-height: 1.5;
			padding: 5px 10px;
			font-size: 1rem;
			color: $primary;
		}
	}
}
