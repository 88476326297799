@import url('https://fonts.googleapis.com/css?family=Crete+Round:400,400i|Istok+Web:400,400i,700,700i');

$base-font-family      : 'Istok Web', sans-serif;
$title-font-family     : 'Crete Round', serif;
$subtitle-font-family  : 'Crete Round',serif;


$base-font-size        : 16px;
$base-line-height      : 1.5;

$base-font-size-normal : 18px;


$breadcrumb-font          : /*1rem*/ 0.889rem/1 $title-font-family;
$header-menu-font         : /*0.875rem*/ 0.778rem/1 $base-font-family;
$header-search-field-font : 0.875rem/1.286 $base-font-family;

$nav-base-font 						: normal $base-font-size $base-font-family;
$nav-base-font 						: 1.25rem/1.286 $title-font-family;

$section-row-title-font 	: 1.750em/1.25 $title-font-family; // /*Nugget title */ 
$card-title-font					: normal 1.125rem/1.22222222222222 $title-font-family;
$side-row-title-font			: 1.625rem/1.230 $title-font-family;

$card-font-family								: $base-font-family;
$card-title-font-family					: $title-font-family;
$card-more-link-font			: normal 1.125rem/1 $title-font-family;
$card-feed-link-font			: normal 0.835rem/1 $title-font-family;
$button-link-font 				: 600 $base-font-size/1 $base-font-family;


/* FORMS */
$button-font 							: normal $base-font-size/1 $title-font-family;
