#site-header {
	.block-menu-block {
		display: none;
		clear:right;
		.main-menu-btn {
			float:right;
			width:56px;
			height:56px;
			text-indent:-999em;
			margin:0;
			.main-menu-btn-icon {
				left:10px;
				height:4px;
				width:36px;
				background-color:$nav-button-bg-color;
				border-radius:2px;
				margin-top:-2px;
				&:before {
					top:-14px;
					left:0;
					height:4px;
					width:36px;
					background-color:$nav-button-bg-color;
					border-radius:2px;
				}
				&:after {
					top:14px;
					left:0;
					height:4px;
					width:36px;
					background-color:$nav-button-bg-color;
					border-radius:2px;
				}
			}
		}
		#main-menu-state:checked ~ .main-menu-btn { background-color:$nav-active-bg-color; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon { height:4px; background-color:$nav-hover-text-color; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before,
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:0; transform:none; background-color:$nav-hover-text-color; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:before { top:-14px; }
		#main-menu-state:checked ~ .main-menu-btn .main-menu-btn-icon:after { top:14px; }
		ul.sm {
			clear:both;
		}
		@include media(880px) {
			clear:right;
			float:right;
			.menu-block-wrapper > ul.menu > li > a > .sub-arrow { display:none; }
		}
		.menu-block-wrapper > ul.menu > li:last-child { position:relative; }
		.menu-block-wrapper > ul.menu > li {
			border-left:none;
		}
		.menu-block-wrapper > ul.menu > li > a {
			background-color:$nav-base-bg-color;
			font:$nav-base-font;
      color:$nav-base-text-color;
			padding:0.625rem 1.25rem;
			
			@include media(880px) {
				margin-right: 10px;
			}
			
			@include media($normal) {
				font-size: $base-font-size-normal;
			}
			
      &:hover {
        background-color:$nav-hover-bg-color;
        color:$nav-hover-text-color;
      }
			&.current, &.current.highlighted {
				background-color:$nav-active-bg-color;
        color:$nav-active-text-color;
				border-radius:$nav-active-border-radius;
				
			}
			&.highlighted {
				background-color:$nav-highlight-bg-color;
				color:$nav-highlight-text-color;
			}
		}
		.menu-block-wrapper > ul.menu > li > a.highlighted {
      background-color:$nav-highlight-bg-color;
      color:$nav-highlight-text-color;
			.sub-arrow {
				background:$arrow-black-down no-repeat scroll center center transparent;
			}
      &:hover {
        background-color:$nav-hover-bg-color;
        color:$nav-hover-text-color;
        .sub-arrow {
          background:$arrow-white-down no-repeat scroll center center transparent;
        }
      }
		}
		ul.menu {
			border:none;
			box-shadow:none;
			&.sm {
				@include media(880px) {
					border-top:none;
				}
			}
			li {
				a {
					cursor:pointer;
					.sub-arrow {
						background:$arrow-white-right no-repeat scroll center center transparent;
						background-size:50%;
						text-indent:-999em;
						overflow:hidden;
						right:0;
					}
				}
				ul.menu {
					background:$nav-sub-bg-color;
					box-shadow:$nav-sub-box-shadow;
					border:$nav-sub-border;
					border-radius:$nav-sub-border-radius;
					li {
						border-top:none;
						a {
							color:$nav-sub-text-color;
							font-size:$base-font-size-normal;
							line-height:1.225;
							padding:0.625rem 1.125rem;
							&:hover, &.current {
								background-color:$nav-hover-bg-color;
								color:$nav-hover-text-color;
								.sub-arrow {
									background:$arrow-white-down no-repeat scroll center center transparent;
									background-size:50%;
									@include media(880px) {
										background-size:contain;
										background:$arrow-white-right no-repeat scroll center center transparent;
										background-size:100%;
									}
								}
							}
							.sub-arrow {
								background:$arrow-white-right no-repeat scroll center center transparent;
								background-size:50%;
								@include media(880px) {
									background:$arrow-blue-right no-repeat scroll center center transparent;
									background-size:contain;
									right:10px;
								}
								text-indent:-999em;
								overflow:hidden;
								right:0;
							}
						}
						&.expanded > a.highlighted {
							background-color:$nav-hover-bg-color;
							color:$nav-hover-text-color;
							.sub-arrow {
								background:$arrow-white-down no-repeat scroll center center transparent;
								background-size:50%;
								@include media(880px) {
									background-size:contain;
									background:$arrow-white-right no-repeat scroll center center transparent;
										background-size:100%;									
								}
							}
						}
					}
				}
			}
		}
	}
}