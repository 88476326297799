html, body {
	color:$primary;
	font-family:$base-font-family;
	font-size:$base-font-size;
	line-height:$base-line-height;
	-webkit-font-smoothing: antialiased;
	
	@include media($normal) {
		font-size:$base-font-size-normal;
		
		header,
		#breadcrumb,
		footer {
			font-size:$base-font-size;
		}
	}
}


h1, h2, h3, h4, h5, h6 {
	color:$primary;
	font-family:$title-font-family;
	margin-top:0;
	text-transform:none;
	
	&.card-title {
		font-family:$title-font-family;
	}
	&.card-title-sm {
		font-family:$title-font-family;
		font-size:1em;
		margin-bottom:$vert-space/4;
		
		a {
			color:$action-default;
			
			&:hover {
				color:$action-hover;
			}
		}
	}
}
h1, h2 {
	font-weight:normal;
}
h3, h4, h5, h6 {
	font-weight:500;
}

.l-region--sidebar-second {
	h3, h4, h5, h6 {
		font-family:$base-font-family;
	}
}
h1 {
	font-size:2rem;
	line-height:1.1875;
	margin-bottom:$vert-space;
}
h2 {
	font-size:1.625rem;
	line-height:1.230;
	margin-bottom:0.5em;
}
h3 { 
	font-size:1.375rem;
	line-height:1.273;
	margin-bottom:0.5rem;
}
h3.block__title {
	
	font-size:1.375em;
	
	@media screen and (min-width: 768px) {
		text-align: center;
		font-size: 1.750em;
	}
}
h4 {
	font-size:1.25em;
	line-height:1.3; 
	margin-bottom:0.5em;
}
h5, h6 {
	font-size:1.063em;
	line-height:1.333;
	margin-bottom:0.5em;
}
p {
	margin:0 0 $vert-space;
}
b, strong {
	font-weight:700;
}

a, a:link, a:active, a:visited {
	color:$action-default;
	text-decoration:none;

	&.active {
		color:$action-active;
		text-decoration:none;
	}
	
	&:hover,
	h1 a:hover, h2 a:hover, h3 a:hover, h4 a:hover, h5 a:hover, h6 a:hover {
		color:$action-hover;
		text-decoration:none; 
	}
}

.date,
.card-date {
	font-size:0.750em;
	font-style:normal;
}

em, i {
	font-style: italic;
}

sup {
	vertical-align: super;
	font-size: smaller;
  display: inline-block;
  padding-top: 1em;
}