.wysiwyg-intro-text {
	color:$secondary;
	font: 1.25em/1.3 $title-font-family;
}
.smaller-text {
	font-size: 0.750em;
	line-height: 1.5;
}
.larger-text {
	font-size: 1.250em;
	line-height: 1.3;
}