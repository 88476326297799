
.ajax-progress ,ajax-progress-throbber, .flag-throbber {
	display:none;
}
body {
	.view {
		.view-filters {
			opacity:1;
			transition:opacity 0.25s ease-in-out;
		}
		.view-content {
			opacity:1;
			transition:opacity 0.5s ease-in-out;
		}
	}
	&.ajax-processing {
		.view {
			.view-filters {
				opacity:0.5;
			}
			.view-content {
				opacity:0;
			}
			* {
				cursor:wait;
			}
		}
	}
}

.node-type-front .section-inner.section-inner-block {
    overflow: visible;
}

#views-exposed-form-lcntdr-proj-search-page .search-field input.default,
#views-exposed-form-lcntdr-research-search-page .search-field input.default {
	min-width:240px;
}

#edit-source-wrapper {
    display: none!important;
}

// DISPLAY YEAR FILTER AND HIDE DEFAULT FROM-TO FILTERS
#views-exposed-form-publications-all,
#views-exposed-form-resources-all,
#views-exposed-form-resources-publications-all {
  #edit-from-wrapper,
  #edit-to-wrapper {
    display: none !important;;
  }
  #edit-year-wrapper {
    display: block !important;
  }
}