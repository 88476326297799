/* MEMBERS NODE */ 

.lcntdr-member-logo {
    float: right;
    margin-right: 20px;
}

.lcntdr-card-position {
	font-size:0.8em;
	margin-top:10px;
}

.card-title .lcntdr-list-title {
	font:500 1rem/1.22222 "Istok Web",sans-serif;
}

/* SIDEBAR */

.view-lcntdr-members-node {
  .card-lcntdr-position {
	font-size:1em;
	font-style:italic;
	margin-bottom:20px;
	margin-top:-10px;
  }
}

.view-lcntdr-members-node {
	ul li {
		margin-bottom: 20px;
	}
}
