// Font styles for the following can be configured in var-typography

$site-max-width : 1600px;

// /* HEADER */ //
$header-bg-color 										: white;

$header-menu-link-color							: $secondary;
$header-menu-link-hover							: $action-hover;

$header-search-field-bg-color				: white;
$header-search-field-text-color			: $secondary;
$header-search-field-box-shadow			: none;
$header-search-field-border					: 1px solid $platinum;
$header-search-field-border-radius	: 0px;


// /* MAIN MENU */ //
$nav-base-bg-color				: $aurometalsaurus;
$nav-base-text-color			: white;
/*$nav-base-padding				 : 0.625rem 1.5625rem;*/

/*$nav-base-padding-smaller : 0.375rem 1.125rem; //for use at awkward breakpoints so the menu still fits on one line*/

$nav-active-bg-color			: $folly;
$nav-active-text-color		: white;
$nav-active-border-radius : 0;

$nav-hover-bg-color				: $action-default;
$nav-hover-text-color			: white;

$nav-highlight-bg-color		: $platinum;
$nav-highlight-text-color : $japanese_indigo;

$nav-button-bg-color			: $secondary;

$nav-sub-bg-color					: $mint_cream;
$nav-sub-text-color				: $japanese_indigo;
$nav-sub-box-shadow				: $box-shadow;
$nav-sub-border						: none;
$nav-sub-border-radius		: none;


// /* BREADCRUMBS */ //
$breadcrumb-bg-color		 	: $mint_cream;
$breadcrumb-action-color 	: $action-hover;
$breadcrumb-hover-color		: $action-default;
$breadcrumb-active-color 	: $aurometalsaurus;
$breadcrumb-div-color			: $aurometalsaurus;


// /* PRIMARY TABS */ //
$tab-active-bg-color		 	: $nav-highlight-bg-color;
$tab-active-text-color	 	: $nav-highlight-text-color;
$tab-active-border			 	: $primary-border;
$tab-inactive-bg-color	 	: $base-background-color;
$tab-inactive-text-color 	: $primary;
$tab-hover-bg-color				: $nav-hover-bg-color;
$tab-hover-text-color			: $nav-hover-text-color;
$tab-border								: 1px solid $nav-highlight-bg-color;
$tab-border-bottom			 	: $primary-border;



// /* CARDS */ //
// /* CARD DEFAULT SETTINGS */
$card-shadow										: $box-shadow;
$card-border										: 0;
$card-border-radius							: 0;
$card-text-padding							: $vert-space $horz-space !default;
$card-margin-bottom							: $vert-space !default;
$card-transition 								: background-color 300ms linear 0s;

// /* EVENT DISPLAY DATES
$card-date-bg-color							: $white; //$mint_cream
$card-date-border								: 1px solid rgba(44, 63, 78, 0.1); //none
$card-date-border-radius				: 0;
$card-date-text-color						: $secondary;
$card-date-font									: 0.8125em/1 $base-font-family;


// /*HOVER STYLES: SAME FOR ALL CARDS */
$card-hover-bg-color					 	: white;
$card-hover-border						 	: none;
$card-hover-shadow						 	: $box-shadow;
$card-action-hover						 	: $bright_cerulean; //colour of card title text looks like when you hover over it
$card-text-hover-color				 	: $secondary; //colour of regular card text when you hover over it
$card-date-hover-text-color 		: lighten($grey,6%);
$card-date-hover-bg-color				: $pale_grey; // allows display dates (e.g. events) to have different hover background from the rest of the card


/*
// --- CARD SUMMARIES ON HOVER
// ----- Card summary can be hidden with the card-hidden-summary mixin.
// ----- un/comment the appropriate line in base/_cards.scss as appropriate
// ----- currently, this applies to box links only, to extend, adjust the mixin in mixins/_card-hidden-summary.scss 
*/
$card-text-hover-bg									: $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-hover-bg		: $card-hover-bg-color; // alternative background for summary text revealed on hover
$card-text-with-summary-title-color	: white; // alternative background for summary text revealed on hover
$card-text-with-summary-text-color	: white; // alternative background for summary text revealed on hover

// /* CARD-LIGHT - light coloured background */
$card-light-bg-color						: white;
$card-light-border-color			 	: transparent;
$card-light-title-color					: $primary;
$card-light-action-default		 	: $action-active;
$card-light-action-hover			 	: $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-light-text-color				 	: $primary;
$card-light-subtitle-color		 	: $primary;

// /* CARD-MEDIUM - medium coloured background */
$card-medium-bg-color						: white;
$card-medium-border-color				: transparent;
$card-medium-title-color				: $primary;
$card-medium-action-default			: $action-active;
$card-medium-action-hover				: $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked
$card-medium-text-color					: $primary;
$card-medium-subtitle-color			: $primary;

// /* CARD-DARK - dark coloured background */
$card-dark-bg-color							: white;
$card-dark-border-color					: transparent;
/*$card-dark-title-color				 : $black;*/
$card-dark-action-default				: $action-active;
/*$card-dark-action-hover				: $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked*/
$card-dark-text-color						: $primary;
$card-dark-subtitle-color				: $primary;

// /* CARD-HIGHLIGHT - alternative coloured background to make cards "pop pop" */
$card-highlight-bg-color			 	: $bright_cerulean;
$card-highlight-border-color	 	: transparent;
/*$card-highlight-title-color		: $pale_grey;*/
$card-highlight-action-default 	: $alice_blue;
/*$card-highlight-action-hover	: $action-hover; // hover color for links within card text e.g. twitter, where the entire card is not linked*/
$card-highlight-text-color		 	: white;
$card-highlight-subtitle-color 	: white;


// * CARD-MORE-LINKS */
$card-more-link-default-bg					: $action-default;
$card-more-link-default-color				: white;
$card-more-link-default-icon				: $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-default-hover-bg		: $action-hover;
$card-more-link-default-hover-color : white;
$card-more-link-default-hover-icon	: $arrow-white-right no-repeat 100% 100% / 14px auto;

$card-more-link-alt-bg							: $folly;
$card-more-link-alt-color						: white;
$card-more-link-alt-icon						: $arrow-white-right no-repeat 100% 100% / 14px auto;
$card-more-link-alt-hover-bg				: $glitter;
$card-more-link-alt-hover-color		 	: $midnight_green;
$card-more-link-alt-hover-icon			: $arrow-green-right no-repeat 100% 100% / 14px auto;



// * CARD-FEED-LINKS */
$card-feed-link-default-bg					: none; // background for the entire link
$card-feed-link-default-color				: $action-default;
$card-feed-link-default-icon				: transparent $arrow-l-blue-right no-repeat scroll 50% 50% / contain; //background for the default :after component
$card-feed-link-default-hover-bg		: none;
$card-feed-link-default-hover-color : $action-hover;
$card-feed-link-default-hover-icon	: transparent $arrow-green-right no-repeat scroll 50% 50% / contain;

$card-feed-link-alt-bg							: none; // background for the entire link
$card-feed-link-alt-color						: white;
$card-feed-link-alt-icon						: transparent $arrow-white-right no-repeat scroll 50% 50% / contain; //background for the alt :after component
$card-feed-link-alt-hover-bg				: none;
$card-feed-link-alt-hover-color 		: $folly;
$card-feed-link-alt-hover-icon			: transparent $arrow-pink-right no-repeat scroll 50% 50% / contain;


//	 /* BUTTON LINKS	 */
$button-link-default-bg								 : $folly;
$button-link-default-border-color			 : $folly;
$button-link-default-color							: white;
$button-link-default-icon							 : none;
$button-link-default-hover-bg					 : $midnight_green;
$button-link-default-hover-border-color : $midnight_green;
$button-link-default-hover-color				: none;
$button-link-default-hover-icon				 : none;

$button-link-alt-bg										 : $folly;
$button-link-alt-border-color					 : $folly;
$button-link-alt-color									: white;
$button-link-alt-icon									 : none;
$button-link-alt-hover-bg							 : $bright_cerulean;
$button-link-alt-hover-border-color		 : $bright_cerulean;
$button-link-alt-hover-color						: white;
$button-link-alt-hover-icon						 : none;


// /* MAIN PAGE */ //
$hero-bg : white;	// /* Should not be the same as $main-page-bg or $palette-default-bg-color for banded sites */
$main-page-bg : white; /*$mint_cream*/
/*$section-main-padding : $vert-space*1.5 0 !default;*/

// /* SLIDES - card-slide-description */ //
$slide-text-bg-color			 : rgba(0, 0, 0, 0.7) ;
$slide-text-color					: white;
$slide-text-font					 : 1.375rem/1.45 $title-font-family;



$slide-dots-bg-color			 : none;
$slide-dots-border-default : 2px solid white;
$slide-dots-border-hover	 : 2px solid $folly;
$slide-dots-bg-active			: $folly;
$slide-dots-border-active	: 2px solid $folly;


// /* SLICK CONTROLS PALETTE */
$slick-default-nav-bg										: none; // used on slide and galler views
$slick-default-controls-bg							: none; // used on twitter views etc
$slick-default-prev-bg									: $arrow-grey-left no-repeat 50% 50% / 12px auto;
$slick-default-prev-hover-bg						: $arrow-pink-left no-repeat 50% 50% / 12px auto;
$slick-default-next-bg									: $arrow-grey-right no-repeat 50% 50% / 12px auto;
$slick-default-next-hover-bg						: $arrow-pink-right no-repeat 50% 50% / 12px auto;
$slick-default-dot-border								: 2px solid $aurometalsaurus;
$slick-default-dot-hover-border					: 2px solid $folly;
$slick-default-dot-active-bg-color 			: $folly;
$slick-default-dot-active-border-color 	: $folly;

$slick-alt-1-nav-bg											: none; // used on slide and galler views
$slick-alt-1-controls-bg								: none; // used on twitter views etc
$slick-alt-1-prev-bg										: $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-1-prev-hover-bg							: $arrow-pink-left no-repeat scroll 50% 50%;
$slick-alt-1-next-bg										: $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-1-next-hover-bg							: $arrow-pink-right no-repeat scroll 50% 50%;
$slick-alt-1-dot-default-border					: 2px solid white;
$slick-alt-1-dot-hover-border						: 2px solid folly;
$slick-alt-1-dot-hover-bg-color					: transparent;
$slick-alt-1-dot-active-bg-color	 			: $folly;
$slick-alt-1-dot-active-border-color 		: $folly;


$slick-alt-2-nav-bg											: $pale_grey; // used on slide and galler views
$slick-alt-2-controls-bg								: none; // used on twitter views etc
$slick-alt-2-prev-bg										: $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-prev-hover-bg							: $arrow-white-left no-repeat scroll 50% 50%;
$slick-alt-2-next-bg										: $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-next-hover-bg							: $arrow-white-right no-repeat scroll 50% 50%;
$slick-alt-2-dot-default-border					: 2px solid $bright_cerulean;
$slick-alt-2-dot-hover-bg-color					: $bright_cerulean;
$slick-alt-2-dot-hover-border						: 2px solid $bright_cerulean;
$slick-alt-2-dot-active-bg-color	 			: $bright_cerulean;
$slick-alt-2-dot-active-border-color		:	$bright_cerulean;/**/




// /* SIDEBAR Container styles */

$sidebar-second-bg			 : none;
$sidebar-second-border	 : none;
$sidebar-second-margin	 : 0;
$sidebar-second-padding	: 0;

$side-row-bg									 : $glitter;
$side-row-border							 : none;
$side-row-border-radius				: 0;
$side-row-box-shadow					 : $card-shadow;
$side-row-padding							: $vert-space $horz-space;
$side-row-inner-mobile-padding : 0;
$side-row-inner-padding				: 0;
$side-row-margin							 : 0 0 $vert-space*1.25;


/* SIDEBAR CARDS */
//@mixin palette-sidebar-cards { @extend %card-light; }
@mixin palette-sidebar-cards {
	@include card-light();
}
@mixin palette-sidebar-card-more-link {
	@include card-more-link-default();
}
@mixin palette-sidebar-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-sidebar-button-link {
	@include button-link-default();
}
@mixin palette-sidebar-slick-controls {
	@include slick-controls-default();
}

$card-sidebar-border				: none;
$card-sidebar-border-radius : 0;
$card-sidebar-shadow				: $card-shadow;
$card-sidebar-text-padding	: $card-text-padding;
$card-sidebar-margin-bottom : $card-margin-bottom*1.5;

// /* CARD-SIDEBAR - light coloured background */
$card-sidebar-bg-color					 : white;
$card-sidebar-border-color			 : transparent;
$card-sidebar-action-default		 : $action-active;
$card-sidebar-text-color				 : $primary;
$card-sidebar-subtitle-color		 : $primary;




// /* SECTION/NUGGET ROWS */ /
/*$section-row-padding					: $vert-space*2 0;
$section-inner-mobile-padding : 0 $horz-space/2;
$section-inner-padding				: 0 $horz-space;*/


// /*Default sets the card colours for main and additional also */
$palette-default-bg-color			: white;
$palette-default-title-color	 : $primary;
$palette-default-text-color		: $primary;
$palette-default-link-color		 : $action-default;
$palette-default-link-hover		: $action-hover;

//Set the card style for the DEFAULT band by replacing the %card-[extend] the mixin below
//@mixin palette-default-cards { @extend %card-light; }
@mixin palette-default-card-more-link {
	@include card-more-link-default();
}
@mixin palette-default-cards {
	@include card-light();
}
@mixin palette-default-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-default-button-link {
	@include button-link-default();
}
@mixin palette-default-slick-controls {
	@include slick-controls-default();
}


$palette-alt-1-bg-color				: $alice_blue;
$palette-alt-1-title-color		 : $primary;
$palette-alt-1-text-color			: $primary;
$palette-alt-1-link-color			: $action-default;
$palette-alt-1-link-hover			: $action-hover;

//Set the card style for the ALT-1 band by replacing the %card-[extend] the mixin below
@mixin palette-alt-1-cards {
	@include card-light();
}
//@mixin palette-alt-1-cards { @extend %card-light; }
@mixin palette-alt-1-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-1-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-1-button-link {
	@include button-link-default();
}
@mixin palette-alt-1-slick-controls {
	@include slick-controls-default();
}


$palette-alt-2-bg-color				: $glitter;
$palette-alt-2-title-color		 : $primary;
$palette-alt-2-text-color			: $primary;
$palette-alt-2-link-color			 : $action-default;
$palette-alt-2-link-hover			: $action-hover;

//Set the card style for the ALT-2 band by replacing the %card-[extend] the mixin below
//@mixin palette-alt-2-cards { @extend %card-light; }
@mixin palette-alt-2-cards {
	@include card-light();
}
@mixin palette-alt-2-card-more-link {
	@include card-more-link-default();
}
@mixin palette-alt-2-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-alt-2-button-link {
	@include button-link-default();
}
@mixin palette-alt-2-slick-controls {
	@include slick-controls-default();
}


$palette-highlight-bg-color		: $midnight_green;
$palette-highlight-title-color : white;
$palette-highlight-text-color	: white;
$palette-highlight-link-color	 : $action-default;
$palette-highlight-link-hover	: $folly;

//Set the card style for the HIGHLIGHT band by replacing the %card-[extend] the mixin below
//@mixin palette-highlight-cards { @extend %card-light; }
@mixin palette-highlight-cards {
	@include card-light();
}
@mixin palette-highlight-card-more-link {
	@include card-more-link-alt();
}
@mixin palette-highlight-card-feed-link {
	@include card-feed-link-alt();
}
@mixin palette-highlight-button-link {
	@include button-link-alt();
}
@mixin palette-highlight-slick-controls {
	@include slick-controls-alt-1();
}


$palette-alt-3-bg-color				: $bright_cerulean;
$palette-alt-3-title-color		 : white;
$palette-alt-3-text-color			: white;
$palette-alt-3-link-color			: $japanese_indigo;
$palette-alt-3-link-hover			: $folly;

//Set the card style for the ALT-3 band by replacing the %card-[extend] the mixin below
//@mixin palette-alt-3-cards { @extend %card-light; }
@mixin palette-alt-3-cards {
	@include card-light();
}
@mixin palette-alt-3-card-more-link {
	@include card-more-link-alt();
}
@mixin palette-alt-3-card-feed-link {
	@include card-feed-link-alt();
}
@mixin palette-alt-3-button-link {
	@include button-link-default();
}
@mixin palette-alt-3-slick-controls {
	@include slick-controls-default();
}


// /* SEARCH */ //
$search-card-bg-color : white;


// /* HIGHCHARTS */ //
/*$highcharts-font-family			: 'Georgia', serif !default;
$highcharts-container-margin : $vert-space*1.5 0 !default;*/


// /* FOOTER */ //
$footer-first-bg					 : $primary;
$footer-first-text				 : white;
$footer-first-action			 : white;
$footer-first-action-hover : $bright_cerulean;
$footer-first-border			 : none;
$footer-first-card-color	 : $card-light-bg-color;

//Set the card style for FOOTER-FIRST by replacing the %card-[extend] the mixin below
//@mixin palette-footer-first-cards { @extend %card-light; }
@mixin palette-footer-first-cards {
	@include card-light();
}
@mixin palette-footer-first-card-more-link {
	@include card-more-link-default();
}

@mixin palette-footer-first-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-first-slick-controls {
	@include slick-controls-default();
}


$footer-second-bg					 : white;
$footer-second-text				 : $secondary;
$footer-second-action			 : $secondary;
$footer-second-action-hover : $action-hover;
$footer-second-card-color	 : $card-light-bg-color;

//Set the card style for FOOTER-SECOND by replacing the %card-[extend] the mixin below
//@mixin palette-footer-second-cards { @extend %card-light; }
@mixin palette-footer-second-cards {
	@include card-light();
}
@mixin palette-footer-second-card-more-link {
	@include card-more-link-default();
}
@mixin palette-footer-second-card-feed-link {
	@include card-feed-link-default();
}
@mixin palette-footer-second-slick-controls {
	@include slick-controls-default();
}


// /* FOOTER CARD-LINKS	-- Follow . social media icons */
$card-links-action-bg						: white;
$card-links-border-radius 			 : 50%;
$card-links-width								: 40px;
$card-links-height							 : 40px;
$card-links-hover-opacity				: 0.8;
$card-links-hover-border-radius	: 0;
$card-links-hover-bg						 : $bright_cerulean ;


$footer-third-bg						: white;
$footer-third-text					: $secondary;
$footer-third-action				: $grey;
$footer-third-action-hover	: $action-hover;




$section-row-padding : 30px 0;