.view-attachments {
	// /* LAYOUT */
	
	ul {
		padding:0;
		margin:0;
	}
	.views-row {
		padding: $vert-space/2 0;
	}
	.views-row-last {
		.attachment-link {
			margin:0;
		}
	}
  
	.attachment-link {
		display:block;
		overflow: hidden;
		margin-bottom:$vert-space/2;
		
		.file-icon {
			float:left;
			margin:0 $horz-space*0.75 0 0;
			min-width:4rem;
			.file-extension {
				padding:16px 16px 11px;
				text-transform:uppercase;
				font-size:0.9375em;
        line-height:1;
				text-align:center;
				font-weight:bold;
			}
			.file-size {
        padding: 2px;
				font-size:0.8750em;			
				text-align:center;
				color:$secondary;
        border:$tertiary-border;
        border-top:none;
				background: white;
			}
		}
		
		.file-name {
			color: $action-hover;
			font: 1.125rem/1.4375 $title-font-family;
			overflow:hidden;
		}
		
		&:hover {
			.file-name {
				color: $action-default;
			}
		}
	}
	// /* FILE TYPE COLOURS */
  .file-type-docx, .file-type-doc {
    .file-extension {
      background:#2d5994;
      color:white;
    }
  }
  .file-type-xlsx, .file-type-xls {
    .file-extension {
      background:#207347;
      color:white;
    }
  }
  .file-type-pptx, .file-type-ppt {
    .file-extension {
      background:#D04727;
      color:white;
    }
  }
  .file-type-pdf {
    .file-extension {
      background:#C11E07;
      color:white;
    }
  }
  .file-type-rtf, .file-type-txt {
    .file-extension {
      background:#023A6D;
      color:white;
    }
  }
  .file-type-jpg, .file-type-png, .file-type-bmp, gif {
    .file-extension {
      background:#FAC74C;
      background:#D19B15;
      color:white;
    }
  }
  .file-type-zip, .file-type-rar, .file-type-7z {
    .file-extension {
      background:black;
      color:white;
    }
  }
} 
