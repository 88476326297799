#sidebar-second {
	.view-id-sections .views-row {
		.section-inner {

			.card-view-3 {
				background: $white;
			}
		}

		.card-select-feed {
			display: inline-block;
		}
	}
	// Fix for combined news & events feeds
	.view-id-news_events {
		.view-content-main {
			width: 100%;
		}
		.views-row {
			width: 100%;
		}
		.view-header h2 {
			border-bottom: none;
		}
	}
	// Fix for exposed filters
	.views-exposed-widget {
		width: 100%;
	}
	.view-id-publications {
		.views-exposed-widgets .views-exposed-widget {
			width: 100%;
		}
	}
	.view-id-resources {
		.views-exposed-widgets .views-exposed-widget {
			width: 100%;
		}
	}
}

.node-type-publication {
	#sidebar-second section {
		background: $glitter;
		padding: $vert-space $horz-space;
		.card-link {
			margin-bottom: 10px;
			a {
				word-break: break-all;
			}
			.card-label {
				display: none;
			}
		}
		.card-label {
			margin-top: 10px;
		}
//		.card-journal {
//			float: left;
//			div {
//				float: left;
//			}
//		}
		.card-citation-date,
		.card-volume,
		.card-pages {
			display: inline;
			font-size: 0.875rem;
			font-weight: 400;
//			vertical-align: super;
		}
		.card-volume::before {
			content: "Vol. ";
			display: inline;
//			float: left;
		}
//		.card-volume::after {
//			content: ": ";
//			display: inline-block;
//			// float: left;
//		}

		.card-volume div {
			display: inline;
		}

	}
}
