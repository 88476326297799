/* RESEACHER NODE */

.node-type-lcntdr-researcher .card-lcntdr-position {
  font-size:1.375rem;
  font-style:italic;
  margin-bottom:10px;
  margin-top:-10px;
}

.card-lcntdr-email {
  margin-bottom:20px;
  font-size:0.9em;
}

.view-id-lcntdr_publications .card {
	background-color:$header-bg-color;
}

.view-id-lcntdr_researcher {
	ul:first-child {
		margin-bottom: 0;
	}
}

.node-type-lcntdr-researcher,
.node-type-lcntdr-project {
	.postscript-first {
		padding: $vert-space 0;
		background: $glitter;
		.postscript_first {
			max-width: $site-max-width;
			margin: 0 auto;
		}
	}
}

.view-lcntdr-researcher ul li {
    margin-bottom: 20px;
}

/* ADD TO CUSTOM WHEN PIPE FINISHED */

.view-publications .card-content-type {
    display: none;
}

.view-lcntdr-publications .card-content-type {
    display: none;
}