.lcntdr,
.lcntdr---dev {
	.postscript-first .section-row.palette-alt-3 {
		@include linear-gradient(to top, rgba(57,184,234,1) 0%, rgba(14,152,208,1) 58%, rgba(22,132,179,1) 100%);
		background-image: $bg-skyline;
		background-repeat: no-repeat;
		background-size: 1600px 200px;
		background-position: 50% 100%; 

		form { 
			background-color: white;
			padding: $vert-space;
			color: $primary;
			margin-bottom: $vert-space*2;
			
		}
	}
	
	.chosen-container-multi .chosen-choices,
	.chosen-container-single .chosen-single,
	.selectric,
	.views-exposed-form select {
		background: none;
		background-color: white;
		background-image: $btn-dropdown;
		background-position: 100% 0;
		background-repeat: no-repeat;
		border: $form-border;
		box-shadow: $form-box-shadow;
		border-radius: 0 21px 21px 0;
		box-sizing: border-box;
		font-family: $base-font-family;
		font-size: $base-font-size;
		margin-bottom: $vert-space/2;
		padding: $vert-space/3 55px $vert-space/3 $horz-space/2;
		transition: border-color;
		width: 100%;
		min-height: 42px;
		/*min-width: 150px;*/
		/*border-right: none;*/
    background-size: auto 40px;
		cursor: pointer;
		
		-webkit-appearance:none;
		-moz-appearance:none;
		-o-appearance:none;
		appearance:none;
		
		 li.search-field input[type="text"] {
		    margin: 4px 0 4px;
		}

		&:hover {
			border-color: darken($form-border-color, 2%);			
			background-image: $btn-dropdown-hover !important;
		}

		&:focus {
			border-color: $action-default;
			box-shadow: $form-box-shadow-focus;
			outline: none;
		}
		
		label {
			height: 36px;
		}
	}
	
	.chosen-container,
	.chosen-container-active.chosen-with-drop .chosen-single,
	.chosen-container-single .chosen-single{
		width: 100% !important;
				
		div {
			/* display: none; */ /* Really?! */
			b {
				display: none;
				/*background: none !important;
				background-position: 0 -10em;*/
			}
		} 
	}
	.chosen-container-active .chosen-choices {
		box-shadow: $form-box-shadow; 
	}
}



.front {	
	
	.view-id-lcntdr_proj_search.view-display-id-counter h2 {
		font:$section-row-title-font;
		text-align: center;
		margin-bottom: 1.250em;
		
		.card-title {
			font-size: inherit;
		}
	}
	
	#views-exposed-form-lcntdr-research-search-page {
		#edit-institution-wrapper,
		.views-reset-button {
			display:none;
		}
	}
	
	#views-exposed-form-lcntdr-proj-search-page,
	#views-exposed-form-lcntdr-research-search-page {
		.views-exposed-form .views-exposed-widget {
			
			width: calc(100%);
			height: 80px;
			padding: .5em 0 0 0;
			
			&.views-submit-button {
				width: 100%;
				height: auto;
				
				.form-submit {
					/*float: left;*/
					margin-top: 0.5em;
					width: 100%;
					
					/*background-color:$nav-active-bg-color;*/
					border-radius: 25px;

					/*&:hover {
						background-color:$nav-base-bg-color;	
					}*/
				}
			}

			@media(min-width:500px){
        width: calc(50%);
				padding: .5em 1em 0 0;
				
				&.views-submit-button {

					.form-submit {
						width: 100px;
					}
				}
      }
			
			@media(min-width:1050px){
        width: calc((100% - 100px) / 4);
				
				&.views-submit-button {
					width: 70px;
					height: auto;
					
					.form-submit {
						margin-top: 1.8em;
					}
				}
      }		
		}
	}
}

.views-exposed-widget input#edit-reset {
  background-color: $button-bg-reset;
  color: $button-text;
	
	&:hover,
  &:focus { 
    background-color: $button-hover-bg-reset;
    color: $button-text;
  }
}


.not-front {
	
	.views-exposed-widgets {
		margin-bottom: $vert-space*2;
	}
	
	#views-exposed-form-lcntdr-proj-search-page,
	#views-exposed-form-lcntdr-research-search-page,
	#views-exposed-form-lcntdr-projects-all,
	#views-exposed-form-publications-all {
		.views-exposed-form .views-exposed-widget {
			
			width: 100%;
			height: 80px;
			padding: .5em 0 0 0;
			
			&.views-submit-button,
			&.views-reset-button{
				width: 100%;
				height: auto;
				
				.form-submit {
					/*float: right;*/
					margin-top: 0.5em;
					width: 100%;
				}
			}

			@media(min-width:500px){
        width: calc(50%);
				padding: .5em 1em 0 0;
				
				&.views-submit-button,
				&.views-reset-button {
						/*float: left;*/
						width: auto;

					.form-submit {
						width: auto;
					}
				}
      }
			
			@media(min-width:900px){
				
				&.views-submit-button,
				&.views-reset-button {
					
					.form-submit {
						margin-top: 1.8em;
					}
				}
      }
		}
	}
}


#views-exposed-form-publications-all {
		.views-exposed-form .views-exposed-widget {
					
			.chosen-container { 
				span {
					margin-top: 3px;
				}
			}
			
			.form-type-date-select {
				width: 100%;
				
				.date-padding,
				.form-item {
					width: 100%;					
				}
			}

			@media(min-width:1050px){
        width: calc((100% - 220px) / 4);
				
				&.views-submit-button,
				&.views-reset-button {
						width: 110px;

					.form-submit {
						width: 100%;
					}
				}
      }	
		}
	}

/* CUSTOM SIDEBARS */

.lcntdr-node-details {
  font-weight: bold;
  margin-top: 10px;
 }

.lcntdr-node-sidebar {
	background-color:$glitter;
	padding:20px;
	margin-bottom:20px;
}

/* CUSTOM LISTS */

.view-lcntdr-project-node.view-display-id-primary_partners,
.view-lcntdr-project-node.view-display-id-researchers {
    margin-bottom: 20px;
}

.lcntdr-list ul {
	list-style:disc;
	margin-left:20px;
}

/* PROJECT SEARCH PAGE */

.page-research-projects-search,
.view-researcher-search {
	
	h1#page-title {
	  /*background-color: #FFFFFF;*/
	  margin-bottom: 0;
	}
	
	.views-exposed-form {
	  /*background-color: #FFFFFF;*/
	  /*padding: 20px;*/
	  margin-bottom:20px;
	}
	
	.views-exposed-widgets {
	  margin-bottom:0;
	}
	
	h2 {
    	margin: 10px 0 30px 0;
	}
	
	main section#content .content-main {
	  background: none;
	  padding: 20px 0px;
	}
}

/* RESEARCHERS SEARCH */

#views-exposed-form-researcher-search-page .view-researcher-search {
	.researcher-position {
    	font-size: 1em;
    	margin-top: 10px;
	}
}

.node-type-front .search-header h2 a {
	color:$midnight_green;
}

/* TWITTER */

.node-type-front .view-twitter {
    margin-bottom: -30px;
}

.view-lcntdr-researcher.view-display-id-project {
    margin-top: 30px;
}

/*.not-front .content-main {
	margin: 0 0 30px;
}*/