/* PROJECT SEARCH FRONT */

.section-row.section-with-form-block,
.section-row.section-with-form-block .section-inner {
	overflow:visible;
} 

.front #views-exposed-form-lcntdr-proj-search-page {
	
	.views-widget-filter-field_lcntdr_primary_partner_target_id_selective,
	.views-widget-filter-field_lcntdr_pis_target_id_selective,
	.views-reset-button {
		display:none;
	}
	
	/*#edit-submit-lcntdr-proj-search {
		background-color:$nav-active-bg-color;
		border-radius: 25px;
		
		&:hover {
			background-color:$nav-base-bg-color;	
		}
	}*/
}

.postscript-first .section-row .search-header h2 {
	  text-align:center;	
	  font: 1.5rem/1.25 $title-font-family;
	  margin-bottom:20px;
}

.front {
	.section-with-form-block h3 {
		color:white;
		margin-bottom:15px;
	}
	
	/*.views-reset-button,
	#edit-institution-wrapper {
		display:none;
	}*/
}