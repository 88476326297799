/* PROJECT SIDEBAR */

.lcntdr-side-researcher {
	background-color: $alice_blue;
	margin-bottom:10px;
}

.view-lcntdr-project-node {
	ul:first-child {
		margin-bottom: 0;
	}
}

.view-lcntdr-project-node ul li {
    margin-bottom: 20px;
}