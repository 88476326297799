 main {
	 #sidebar-second {
		background: $sidebar-second-bg;
		margin: $sidebar-second-margin;
		padding: $sidebar-second-padding;
	}

	.side-row {
		background: $side-row-bg;
		border: $side-row-border;
		border-radius: $side-row-border-radius;
		margin: $side-row-margin;
		padding:$side-row-padding;
		overflow: hidden;
		clear: both;
		display: block;

		@include palette-sidebar-slick-controls;
		@include palette-sidebar-button-link;

		 &.section-with-slider {
			 padding:0;
		 }

		.section-inner {
			padding: $side-row-inner-padding;
			@media (max-width:$narrow) {
				padding: $side-row-inner-mobile-padding;
			}		
		}

		.card-title {
			h2 {
				font: $side-row-title-font;
				border-bottom: 1px solid #D8D8D8;
				padding: 0 $horz-space $vert-space;
				margin: 0 -20px $vert-space;
				text-align: center;
			}
			
			&.card-title-with-description {
				h2 {
					border-bottom: none;
				}
			}
		}
		.card-title.card-title-with-description + .card-description-et {
			border-bottom: 1px solid #D8D8D8;
			padding-bottom: $vert-space;
			margin: 0 -20px;
			padding: 0 20px;
		}

		.card {
			@include palette-sidebar-cards;
			border: $card-sidebar-border;
			border-radius: $card-sidebar-border-radius;
			box-shadow: $card-sidebar-shadow ;
			margin-bottom: $card-sidebar-margin-bottom;
			
			.card-text {
				padding: $card-text-padding;
			}
		}
		a.card:hover {
			@include card-hover;
		}
		
		.views-row-last .card {
			margin-bottom: 0;
		}
		
		&:not(.view-type-slider) {
			.card-more-link {
				@include palette-sidebar-card-feed-link;
				display: block !important;
				text-align: right;
				a.card {
					font-size: 0.875rem;
					padding: 0;
					margin: $card-margin-bottom 0 0 0;
					padding: 0;
					box-shadow: none;
					float: right;
					clear: both;
					&:after {
						background-size: 12px auto;
					}
				}
			}	
		}
		.card-feed-link {
			@include palette-sidebar-card-feed-link;
		}		
	}
}