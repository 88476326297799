// /* DEFAULT CARD LAYOUT	*/
.card {
	border:$card-border;
	border-radius: $card-border-radius;
	box-shadow:$card-shadow;
	margin: 4px 0 $card-margin-bottom 4px;
	transition: $card-transition;
	
	&.slick-slide {
		margin-top: 10px;
	}

	
	.card-title,
	.card-title-et,
	.card-title-field {
		font-family:$card-title-font-family;
	}
	
	h3 {
		display:block;
		font:$card-title-font;
		
		.card-title-field,
		.card-title-et,
		.card-title {
			display:block;
			font:$card-title-font;
		}
	}

	.card-text {
		padding: $card-text-padding;
	}
}

.card-display-date {
	background: $card-date-bg-color;
	border-bottom:$card-date-border;
	border-radius:$card-date-border-radius;
	color:$card-date-text-color;
	font:$card-date-font;
	padding:12px 20px 10px;
}
// /* MORE LINK */
.section-row:not(.view-type-slider),
.side-row:not(.view-type-slider) {
	.card-more-link {
		display:table !important;
		font:$card-more-link-font;
		margin-left:2.35765%;
		text-align:center;

		@media (min-width:0) and (max-width:540px) {
			margin-left:0;
			width:100%;
			//display: block;
		}

		a.card {
			box-sizing:border-box;
			display:table-cell;
			font:$card-more-link-font;
			padding:$vert-space $horz-space;
			vertical-align:middle;
			width:100%;

			@media (min-width:0) and (max-width:719px) {
				display: block;
			}

			&:after {
				content:" ";
				display:inline-block;
				height:16px;
				margin:0 0 -2px 10px;
				width:14px;
			}
		}
	}

	&.row-max-1 {
		.card-more-link,
		.card-last-link-url {
			margin-left: 0;
			float: right;
		}
	}
}

// /* FEED LINK */
.card-feed-link {
	clear: both;
	display: block;
	float: right;
	font:$card-feed-link-font;
	overflow: hidden;
	a {
		&:after {
			content: "";
			display: inline-block;
			width: 13px;
			height: 13px;
			margin: 0 0 -1px 10px;
			vertical-align: middle;
		}
	}
}

.card-title-field,
.card-title-et,
.card-title {
	display:block;
	font:normal 1.125rem/1.22222222222222 $title-font-family;
	
	h2,h3,h4,h5,h6 {
		font-family: $title-font-family;
	}
}

/*
.card-title-field,
.card-title-et,
.card-title {
	display:block;
	font:normal 1.125rem/1.22222222222222 $title-font-family;
	
	h2,h3,h4,h5,h6 {
		font:normal 1.125rem/1.22222222222222 $title-font-family;
	}	
}
.card-title {
	font-size: inherit;
}
*/

.card-related {
	display:block;
	h4 {
		font:bold 1.125rem/1.22222222222222 $title-font-family;
	}
}
.card-shadow {
	box-shadow: $card-shadow;
}
.card-subtitle {
 font-weight: normal;
 line-height:1.375em;
 margin-bottom: 5px;
}
.tweet .card-text {
	color: $primary;
	display:block;
	font:normal 1em/1.5 $base-font-family;
}

.card-logo {
	background:#FFFFFF;
}
.card-video {
	margin-bottom:15px;
	
	span.ext {
		display:none;
	}
}
.card-tags {
	color: $secondary;
	font-size: 0.875em;
	font-weight: 600;
}



.card-citation {
	font-style:italic;
}
.card-location {
	margin-top: 10px;
}
.card-date,
.card-location {
	font-size:0.875em;
	line-height:1.57142857142857;
	color:$card-date-text-color;
}
.card-type {
	font-size:0.875rem;
	line-height:1.57142857142857;
	color:$card-date-text-color;
}
.card-label {
	font-weight:bold;
}
.card-start-date {
	margin-right:2px;
	padding:$vert-space*0.7 $horz-space*0.7 $vert-space*0.2;
	text-align:center;
	
	.card-date-day {
		font-size:1.75em;
		line-height:0.78571428571429;
	}
	.card-date-month {
		font-size:0.875em;
		line-height:1.22222222222222;
		text-transform:uppercase;
	}
}
.card-date-wrapper {
	background: $white;
	padding: 0 10px;
}

.card-date-display-override {
	font-size: 0.875em;
}
.card-resource-type,
.card-content-type {
	display:inline-block;
	font-weight: 400;
	font-size: 0.875em;
}
//#sidebar-second .card-journal {
//	margin-top: 0;
//	&:after {
//		content:"|";
//		margin: 3px;	
//	}
//}
.card-journal {
	display: inline-block;
	font-weight: 400;
	font-size: 0.875em;
	margin-top: 10px;
	
	&:after {
		content:"";
		margin: 0;	
	}
}
.card-email {
	font-weight: 600;
}

.card-inline-block {
	text-align: center;
	
	.card-content {
		float: none;
		display: inline-block;
		vertical-align: top;
		text-align: initial;
		margin: 0 0.8% 20px;
	}
}
.card-minwidth-narrow li {
	@include media($narrow) {
		min-width: 325px;
	}
}
.card-link-arrow {
	background:no-repeat scroll calc(100% - 15px) 50% #FFFFFF;
	padding:10px 30px 10px 15px;
	
	@include media($narrow) {
		padding:15px 35px 15px 20px;
	}

	&:hover {
		background:no-repeat scroll calc(100% - 15px) 50% #FFFFFF;
	}
}
.views-row-last > .card-link-arrow,
.card .views-row-last > .card {
		margin-bottom: 0;
}

.card-float-right {	
	margin:0 0 $vert-space/2;
	text-align:center;
	
	@include media($narrow) {	
		float:right;
		margin-left:$horz-space;
		max-width:40%;
	}
}
.card-float-left {
	margin:0 0 $vert-space/2;
	text-align:center;
	
	@include media($narrow) {	
		float:left;
		margin-right:$horz-space;
		max-width:40%;	
	}
}
.card-job-title {
	color:$secondary;
	font-size:1em;
	line-height:1.375;
}



.card-source .label {
	float: left;
}

div:not(.main-content) .card-image p {
	display: none;
}